.cus-cancelbtn {
  background-color: #080520b8;
  padding: 4px 8px;
  border-radius: 13px;
  font-size: 18px;
}
.form-control {
  background: transparent;
  color: white;
}

.form-control:focus {
  background: transparent;
  color: white;

}

.form-control::placeholder {
  color: grey;
}

@media only screen and (max-width: 600px) {
  .cus-cancelbtn {
    background-color: #080520b8;
    padding: 4px 8px;
    border-radius: 13px;
    font-size: 18px;
  }

}